<template>
    <div class="defaultTemplate_container"></div>
</template>
<script>
export default {
    name:"defaultTemplate"
}
</script>
<style>
.defaultTemplate_container{
    height:100%;
    width:100%;
    background:#fff;
}
</style>